@media (max-width: 991px) {
    .ant-picker-dropdown, .ant-picker-date-panel{
        z-index: 99999 !important;
    }
    .ant-row{
        flex-flow: column !important;
    }
    .hmEHae, .UoUCq {
        padding: 0 !important;
    }
    .ctRBtA.transparent{
        background-color: white !important;
    }
    .efrcTJ .hamburg-btn{
        color: #fa034f !important;
    }
    .jkdQvL .sticky-outer-wrapper nav.transparent > div > div a h3 {
        color: #fa034f !important;
    }
    .jkdQvL .sticky-outer-wrapper nav.transparent > div > div a img {
        opacity: 1 !important;
        width: 28px !important;
    }    
    .ctRBtA.transparent .hamburg-btn > span {
        background-color:  #2C2C2C !important;
    }
    .cJcsxV button[type='submit'].ant-btn svg {
        top: 40% !important;
    }
    .dEchfe .geo-btn {
        bottom: 35px !important;
    }
    .dEchfe .map_autocomplete input {
        padding: 0 40px 0 46px!important;
    }
    .jlAeJH{
        padding: 10px !important;
    }
    .iGRqSi {
        padding: 0 !important;
    }
}

span.diiHta a {
    color: #fa034f !important;
}

